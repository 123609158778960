import { UserData } from '../api/product';
import { ProductCardDetails } from '../product/ProductCard';
import ProductListTool from './ProductListTool';
import ProductRatingTool from './ProductRatingTool';
import ProductShareTool from './ProductShareTool';

interface Props {
    product: ProductCardDetails;
    productUserData?: { favourite: boolean; review?: { rating: number; comment: string }; inUserDefinedList: boolean };
    onUserDataChange(userData: UserData): void;
    showRatingTool?: boolean;
}

export default function ProductTools({ product, productUserData, onUserDataChange, showRatingTool = true }: Props) {
    const { comment, rating } = productUserData?.review || { comment: '', rating: undefined };
    return (
        <div className="product-tools no-print">
            {showRatingTool && <ProductRatingTool productCode={product.code} rating={rating} comment={comment} onUserDataChange={onUserDataChange} />}
            <ProductListTool
                product={product}
                inUserDefinedList={productUserData?.inUserDefinedList}
                favorite={productUserData?.favourite}
                onUserDataChange={onUserDataChange}
            />
            <ProductShareTool url={product.url} title={product.name} />
        </div>
    );
}
