import Modal from '../generic/Modal';
import { ProductCardDetails } from '../product/ProductCard';
import StoreStockLocator from './StoreStockLocator';

export interface Props {
    product: ProductCardDetails;
    isOpen: boolean;
    onClose(): void;
}

export default function StoreStockLocatorModal({ isOpen, product, onClose }: Props) {
    return (
        <Modal onClose={onClose} isOpen={isOpen} size="large" padding={true}>
            <StoreStockLocator product={product} />
        </Modal>
    );
}
