import { useContext } from 'react';
import { UserData } from '../api/product';
import { addToWishlist, removeFromWishlist } from '../api/wishlist';
import Icon from '../generic/Icon';
import { TrackingContext } from '../generic/TrackingContext';

interface Props {
    productCode: string;
    isFavourite: boolean | undefined;
    onUserDataChange(userData: UserData): void;
}

export default function ProductFavouriteTool({ productCode, isFavourite = false, onUserDataChange }: Props) {
    const { item_id, index, item_list_name, item_list_id } = useContext(TrackingContext);

    const onToggleFavourite = async () => {
        if (isFavourite) {
            const userData = await removeFromWishlist('favourite', productCode);
            onUserDataChange(userData);
        } else {
            const userData = await addToWishlist('favourite', productCode);
            onUserDataChange(userData);
            gtag('event', 'add_to_wishlist', {
                wishlist_type: 'favourite',
                items: [{ item_id, index }],
                item_list_id,
                item_list_name,
            });
        }
    };

    return (
        <button
            className="my-list-favorite-btn"
            onClick={onToggleFavourite}
            title={isFavourite ? 'Fjern fra favoritter' : 'Merk som favoritt'}
            aria-label={isFavourite ? 'Fjern fra favoritter' : 'Merk som favoritt'}
        >
            <Icon className={isFavourite ? 'icon-favorite selected' : 'icon-favorite '} />
            Mine favoritter
        </button>
    );
}
