import { PortableTextComponentProps } from '@portabletext/react';
import SanityImage, { ImageWithMetadata, imageWithMetadataGroq } from '../components/SanityImage';
import useDataAttribute from '../infrastructure/DataAttributeContext';
import groq from '../infrastructure/groq';

export const linkCardGroq = groq`
{
    reference -> {
        _id,
        _type,
        title,
        bannerImage ${imageWithMetadataGroq},
    },
    linkText,
    kicker,
    showImage
}
`;

const LinkCardImageSizes = [150, 150 * 2, 150 * 3];

interface LinkCardValue {
    _key: string;
    reference?: {
        path: string;
        title: string;
        bannerImage: ImageWithMetadata;
    };
    kicker: string;
    linkText?: string;
    showImage?: boolean;
}

export function LinkCard({ value }: PortableTextComponentProps<LinkCardValue>) {
    const attr = useDataAttribute();
    return (
        <a className="link-card" href={value?.reference?.path} data-sanity={attr?.([{ _key: value._key }])}>
            <h2>
                <span>{value.kicker}</span>
                {value.linkText || value.reference?.title}
            </h2>
            {value.showImage && <SanityImage image={value.reference?.bannerImage} sizes="150px" widths={LinkCardImageSizes} aspectRatio={16 / 9} />}
        </a>
    );
}
