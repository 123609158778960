import { useContext, useRef, useState } from 'react';
import { useDebounceCallback } from 'usehooks-ts';
import { UserData, commentProduct, rateProduct } from '../api/product';
import ErrorBox, { ErrorBoxType } from '../generic/ErrorBox';
import Spinner from '../generic/Spinner';
import { TrackingContext } from '../generic/TrackingContext';
import { UserContext } from '../login/userContext';
import eventTargetValue from '../utils/eventTargetValue';
import useBooleanState from '../utils/useBooleanState';
import ReviewStars from './ReviewStars';

export interface Props {
    productId: string;
    review:
        | {
              rating: number;
              comment: string;
          }
        | undefined;
    onUserDataChange(userData: UserData): void;
}

export default function RatingAndComment({ productId, review, onUserDataChange }: Props) {
    const { item_list_id, item_list_name } = useContext(TrackingContext);
    const { showLoginModal } = useContext(UserContext);
    const [isLoading, setLoading] = useBooleanState();
    const [error, setError] = useState<ErrorBoxType | null>(null);
    const [rating, setRating] = useState(review?.rating ?? 0);
    const [comment, setComment] = useState(review?.comment ?? '');
    const hasTrackedComment = useRef(false);

    const saveComment = useDebounceCallback((comment: string) => {
        setLoading.toTrue();
        setError(null);
        commentProduct(productId, { comment }).then(onUserDataChange).catch(setError).finally(setLoading.toFalse);
    }, 500);

    const saveRating = useDebounceCallback((rating: number) => {
        setLoading.toTrue();
        setError(null);
        rateProduct(productId, { rating }).then(onUserDataChange).catch(setError).finally(setLoading.toFalse);
    }, 500);

    return (
        <form className="product-item__rating white">
            <ReviewStars
                productCode={productId}
                rating={rating}
                onSelectStar={(stars) => {
                    if (!showLoginModal()) {
                        setRating(stars);
                        saveRating(stars);
                        gtag('event', 'rating', {
                            stars,
                            comment: !!comment,
                            item_id: productId,
                            item_list_id,
                            item_list_name,
                        });
                    }
                }}
            />
            <textarea
                className="product-item__comment form-control"
                id={`${productId}_comment`}
                name="comment"
                placeholder="Skriv et notat"
                onClick={(e) => showLoginModal() && e.preventDefault()}
                value={comment}
                onChange={eventTargetValue((comment) => {
                    if (!showLoginModal()) {
                        setComment(comment);
                        saveComment(comment);
                        if (!hasTrackedComment.current) {
                            hasTrackedComment.current = true;
                            gtag('event', 'rating', {
                                comment: true,
                                item_id: productId,
                                item_list_id,
                                item_list_name,
                            });
                        }
                    }
                })}
            />
            <label className="sr-only" htmlFor={`${productId}_comment`}>
                Skriv et notat
            </label>
            <Spinner isSpinning={isLoading} />
            <ErrorBox errors={error} />
        </form>
    );
}
