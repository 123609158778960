import { useContext } from 'react';
import { UserData } from '../api/product';
import Icon from '../generic/Icon';
import { UserContext } from '../login/userContext';
import { ProductCardDetails } from '../product/ProductCard';
import { toBooleanState } from '../utils/useBooleanState';
import useStateWithSideEffect from '../utils/useStateWithSideEffect';
import ProductListModal from './ProductListModal';

interface Props {
    product: ProductCardDetails;
    inUserDefinedList: boolean | undefined;
    favorite: boolean | undefined;
    onUserDataChange(userData: UserData): void;
}

function trackOpenModal(open: boolean) {
    if (open)
        gtag('event', 'modal_view', {
            modal_name: 'ProductListTool',
        });
}

export default function ProductListTool({ product, inUserDefinedList = false, favorite = false, onUserDataChange }: Props) {
    const { showLoginModal } = useContext(UserContext);
    const [isOpen, setIsOpen] = toBooleanState(useStateWithSideEffect(false as boolean, trackOpenModal));

    const openModal = () => {
        if (!showLoginModal()) {
            setIsOpen.toTrue();
        }
    };

    const getListFavoriteIcon = () => {
        let iconName = 'icon-review';
        if (inUserDefinedList) {
            iconName += '-list';
        }
        if (favorite) {
            iconName += '-favorite';
        }
        return <Icon className={`${iconName}`} />;
    };

    return (
        <>
            <button className="btn-icon" onClick={openModal} title="Min liste" aria-label="Min liste">
                {getListFavoriteIcon()}
            </button>
            {isOpen && <ProductListModal product={product} favorite={favorite} onClose={setIsOpen.toFalse} onUserDataChange={onUserDataChange} />}
        </>
    );
}
