import type { SanityDocument } from '@sanity/client';
import cn from 'classnames';
import { ComponentType, useMemo } from 'react';
import { useEventListener } from 'usehooks-ts';
import { Facet, FacetData, FacetGroup, FacetValue } from '../../model/Facets';
import FacetButton from '../../search/FacetButton';
import SearchFacets from '../../search/SearchFacets';
import { updateSearchParams } from '../../utils/url';
import { toArrayState } from '../../utils/useArrayState';
import useBooleanState from '../../utils/useBooleanState';
import useStateWithSideEffect from '../../utils/useStateWithSideEffect';
import { ScopeDataAttribute } from '../infrastructure/DataAttributeContext';
import * as style from './Grid.module.less';
import { TaggedDocumentTypes } from './TaggedGridItem';

export type TaggedSanityDocument<T extends string = TaggedDocumentTypes> = SanityDocument & { tags?: Tag[]; _type: T };

export interface Tag {
    _key: string;
    _id: string;
    name: string;
    slug: {
        current: string;
    };
    mainTagCategory?: {
        _id: string;
        name: string;
        slug: {
            current: string;
        };
    };
}

interface Props<T extends TaggedSanityDocument> {
    selectedTags?: string[];
    list: T[];
    component: ComponentType<T>;
}

function updateTagsInUrl(tags: string[]) {
    const params = new URLSearchParams(location.search);
    params.delete('tag');
    for (const tag of tags) {
        params.append('tag', tag);
    }
    updateSearchParams(params);
}

export function FilterableGrid<T extends TaggedSanityDocument>({ list, selectedTags: initialSelectedTags = [], component }: Props<T>) {
    const [showFacetPopover, setShowFacetPopover] = useBooleanState();
    const [selectedTags, setSelectedTags] = toArrayState(useStateWithSideEffect(initialSelectedTags, updateTagsInUrl));

    const filteredList = useMemo(() => filterByTags(list, selectedTags), [list, selectedTags]);
    const facets = useMemo(() => getFacets(filteredList, selectedTags), [filteredList, selectedTags]);

    useEventListener('popstate', () => {
        const tags = new URL(location.href).searchParams.getAll('tag');
        setSelectedTags(tags);
    });

    return (
        <>
            <div className={style.grid}>
                {facets.any && (
                    <div className={style.mobileFacets}>
                        <FacetButton onClick={setShowFacetPopover.toTrue} title={`Endre filtre (${facets.selectedFacets.length})`} />
                    </div>
                )}
                <SearchFacets
                    facets={facets}
                    facetsPopover={showFacetPopover}
                    totalResults={filteredList.length}
                    onFacetValueSelect={setSelectedTags.append}
                    onFacetValueUnselect={setSelectedTags.remove}
                    onFacetsCloseClicked={setShowFacetPopover.toFalse}
                    onLocationChange={() => undefined}
                    onResetClicked={() => setSelectedTags([])}
                />
                <Grid items={filteredList} component={component} />
            </div>
        </>
    );
}

export function Grid<T extends SanityDocument>({
    items,
    component: Component,
    autoFit,
}: {
    items: T[];
    component: ComponentType<T>;
    autoFit?: boolean;
}) {
    return (
        <ul className={cn(style.list, { [style.autoFit]: autoFit })}>
            {items?.filter(Boolean).map((d) => (
                <ScopeDataAttribute key={d._id} id={d._id} type={d._type}>
                    <li>
                        <Component {...d} />
                    </li>
                </ScopeDataAttribute>
            ))}
        </ul>
    );
}

function filterByTags<T extends TaggedSanityDocument>(list: T[], selectedTags: string[]): T[] {
    if (!selectedTags.length) return list;
    return list.filter((l) => selectedTags.every((s) => l.tags?.some((t) => t.slug.current === s)));
}

function getFacets<T extends TaggedSanityDocument>(list: T[], selectedTags: string[]): FacetData {
    const facetGroups = getTags<T>(list, selectedTags);

    return {
        groupedFacets: facetGroups.filter((g) => !g.facets.flatMap((f) => f.values).some((v) => selectedTags.includes(v.code))),
        selectedFacets: facetGroups.flatMap((t) => t.facets).filter((f) => f.values.some((v) => selectedTags.includes(v.code))),
        unselectedFacets: [],
        facetOrder: [],
        any: facetGroups.length > 0,
    };
}

function getTags<T extends TaggedSanityDocument>(list: T[], selectedTags: string[]): FacetGroup[] {
    const tags = list.flatMap((l) => l.tags).filter((t) => t != undefined);

    const groups = Object.entries(Object.groupBy(tags, (t) => t.mainTagCategory?.slug.current ?? 'singleValues')) as [string, Tag[]][];

    const tagGroups = groups.map(([, values]) => ({
        mainTagCategory: values![0].mainTagCategory,
        code: values![0].mainTagCategory?.slug.current ?? 'singleValues',
        name: values![0].mainTagCategory?.name ?? 'singleValues',
        singleValues: !values![0].mainTagCategory,
        values: Object.entries(Object.groupBy(values!, (v) => v.slug.current)).map(([code, list]) => ({
            code,
            name: list![0].name,
            count: list!.length,
        })),
    }));

    return tagGroups
        .map(
            ({ mainTagCategory, values }) =>
                ({
                    code: mainTagCategory?.slug.current ?? 'singleValues',
                    name: mainTagCategory?.name ?? 'singleValues',
                    priority: mainTagCategory ? 1 : 2,
                    expanded: !mainTagCategory,
                    facets: [
                        {
                            code: mainTagCategory?.slug.current ?? values[0].code,
                            name: mainTagCategory?.name ?? values[0].name,
                            priority: 0,
                            multiSelect: false,
                            booleanFacet: !mainTagCategory,
                            values: values.map((value) => {
                                const selected = selectedTags.includes(value.code);
                                return {
                                    ...value,
                                    selected,
                                    query: value.code,
                                    href:
                                        '?' +
                                        (selected
                                            ? selectedTags
                                                  .filter((t) => t !== value.code)
                                                  .map((t) => 'tag=' + t)
                                                  .join('&')
                                            : [...selectedTags, value.code].map((t) => 'tag=' + t).join('&')),
                                } satisfies FacetValue;
                            }),
                        } satisfies Facet,
                    ],
                }) satisfies FacetGroup,
        )
        .sort((a, b) => a.priority - b.priority || a.name.localeCompare(b.name));
}
