import * as api from '../api';
import { storeAPI } from '../config';
import { userLocationURL } from '../config/SearchAPIConfig';
import Icon from '../generic/Icon';
import preventDefault from '../utils/preventDefault';
import { useOnMount } from '../utils/useComponentDidMount';
import SearchBox from './SearchBox';

export interface Coords {
    latitude: number;
    longitude: number;
}

export interface Props {
    onLocationChange(pos: Coords): void;
    onFail(message: string): void;
    onLocationFail(message: string): void;
    startWithLocationSearch?: boolean;
    onItemSelected?: (name: string, label: string) => void;
}

export default function StoreLocatorForm({ startWithLocationSearch = true, onLocationChange, onFail, onLocationFail, onItemSelected }: Props) {
    const fetchGeoPosition = () => {
        if (!navigator.geolocation) {
            onFail('Du benytter en nettleser som ikke støtter posisjonering.');
            return;
        }

        getCurrentPosition().then(onLocationChange, onLocationFail);
    };

    useOnMount(() => {
        if (startWithLocationSearch) {
            fetchGeoPosition();
        }
    });

    return (
        <div className="store-finder-search">
            <SearchBox
                onSearch={(query) => query && locationSearch(query).then(onLocationChange, onFail)}
                onItemSelected={onItemSelected}
                autocomplete={autoComplete}
            />

            {!startWithLocationSearch && (
                <button
                    type="button"
                    className="btn btn--transparent locate-button"
                    onClick={preventDefault(fetchGeoPosition)}
                    aria-label="Finn nærmeste Vinmonopol"
                >
                    <Icon className="icon-location-pin" />
                </button>
            )}
        </div>
    );
}

interface Response {
    stores: {
        displayName: string;
        id: string;
    }[];
}

async function autoComplete(input: string) {
    const { stores } = await api.getAnonymously<Response>(storeAPI.autocompleteURL(input.toLowerCase()));
    return stores.map(({ displayName, id }) => ({
        value: id,
        label: displayName,
    }));
}

async function locationSearch(query: string) {
    return api.getAnonymously<Coords>(userLocationURL(), { query: query.toLowerCase() });
}

async function getCurrentPosition() {
    return await new Promise<Coords>((res, rej) =>
        navigator.geolocation.getCurrentPosition(
            ({ coords: { latitude, longitude } }) => {
                res({ latitude, longitude });
            },
            (error) => {
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        return rej(
                            'Vi har ikke tilgang til din posisjon. Hvis du ønsker å gi oss denne tilgangen, bør du sjekke tilgang til lokasjonstjenester i nettleserens innstillinger eller i systeminnstillinger.',
                        );
                    case error.POSITION_UNAVAILABLE:
                        return rej('Din posisjon er ikke tilgjengelig.');
                    case error.TIMEOUT:
                        return rej(
                            'Vi kunne ikke finne din posisjon. Det kan hende du har skrudd av lokasjonstjenester i systeminnstillinger. Vennligst prøv igjen.',
                        );
                    default:
                        return rej('Det oppstod en feil ved henting av din posisjon.');
                }
            },
            { timeout: 5000 },
        ),
    );
}
