import cn from 'classnames';
import Expandable from '../Expandable/Expandable';
import { WrapTrackingContext } from '../generic/TrackingContext';
import type { Facet, FacetGroup } from '../model/Facets';
import FacetValue from './FacetValue';
import * as style from './Facets.module.less';
import StoreFacet, { Props as StoreFacetProps } from './StoreFacet';

export interface Props extends StoreFacetProps {
    facets: FacetGroup[];
    onFacetValueSelect(query: string): void;
}

export function Facets({ facets, onFacetValueSelect, onLocationChange }: Props) {
    return (
        <ul className={style.facets}>
            {facets.map((facetGroup) => (
                <li key={facetGroup.code} className={`facet-group__${facetGroup.code}`}>
                    <FacetGroup
                        key={facetGroup.code}
                        facetGroup={facetGroup}
                        onFacetValueSelect={onFacetValueSelect}
                        onLocationChange={onLocationChange}
                    />
                </li>
            ))}
        </ul>
    );
}

interface FacetGroupProps extends StoreFacetProps {
    facetGroup: FacetGroup;
    onFacetValueSelect(query: string): void;
}

function FacetGroup({ facetGroup, onFacetValueSelect, onLocationChange }: FacetGroupProps) {
    return facetGroup.expanded ? (
        <FacetList facets={facetGroup.facets} onFacetValueSelect={onFacetValueSelect} onLocationChange={onLocationChange} />
    ) : (
        <WrapTrackingContext set={{ context: 'Filter' }}>
            <Expandable title={facetGroup.name}>
                <FacetList facets={facetGroup.facets} onFacetValueSelect={onFacetValueSelect} onLocationChange={onLocationChange} />
            </Expandable>
        </WrapTrackingContext>
    );
}

export interface FacetListProps extends StoreFacetProps {
    facets: Facet[];
    onFacetValueSelect(query: string): void;
}

export function FacetList({ facets, onFacetValueSelect, onLocationChange }: FacetListProps) {
    if (facets.length === 1 && !facets[0].booleanFacet) {
        return (
            <WrapTrackingContext set={{ filter_type: facets[0].name }}>
                <FacetGroupItem facet={facets[0]} onFacetValueSelect={onFacetValueSelect} onLocationChange={onLocationChange} />
            </WrapTrackingContext>
        );
    }

    return (
        <ul className={style.facetGroup}>
            {facets.map((facet) => (
                <WrapTrackingContext key={facet.code} set={{ filter_type: facet.name }}>
                    {facet.booleanFacet ? (
                        <li className={style.booleanFacet}>
                            <FacetValue value={facet.values[0]} onFacetValueSelect={onFacetValueSelect} count />
                        </li>
                    ) : (
                        <li>
                            <div className={style.facetName}>{facet.name}</div>
                            <FacetGroupItem facet={facet} onFacetValueSelect={onFacetValueSelect} onLocationChange={onLocationChange} />
                        </li>
                    )}
                </WrapTrackingContext>
            ))}
        </ul>
    );
}

function FacetGroupItem({ facet, onFacetValueSelect, onLocationChange }: FacetProps & StoreFacetProps) {
    return (
        <>
            {facet.code === 'availableInStores' && <StoreFacet onLocationChange={onLocationChange} />}
            <Facet facet={facet} onFacetValueSelect={onFacetValueSelect} />
        </>
    );
}

interface FacetProps {
    facet: Facet;
    onFacetValueSelect(query: string): void;
}

function Facet({ facet, onFacetValueSelect }: FacetProps) {
    return (
        <ul className={cn(style.facetValues, facet.listClass)}>
            {facet.values.map((value) => (
                <li key={value.code}>
                    <FacetValue value={value} onFacetValueSelect={onFacetValueSelect} count />
                </li>
            ))}
        </ul>
    );
}
