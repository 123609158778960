import { ImageWithMetadata, imageWithMetadataGroq } from '../components/SanityImage';
import useDataAttribute from '../infrastructure/DataAttributeContext';
import groq from '../infrastructure/groq';
import { TaggedSanityDocument } from './Grid';
import * as style from './Grid.module.less';
import GridItemImage from './GridItemImage';

export const taggedGridItemGroq = groq`{
    _id,
    _type,
    title,
    ingress,
    'category': select(
        defined(parent -> parent) => parent -> title,
    ),
    bannerImage ${imageWithMetadataGroq},
    tags[] {
        _key,
        ...(@ -> {
            _id,
            name,
            slug,
            mainTagCategory -> {
                _id,
                name,
                slug
            }
        })
    }
}`;

export type TaggedDocumentTypes = 'recipeArticle' | 'drinkRecommendationArticle' | 'blogArticle' | 'vintageArticle';

export type TaggedGridItemDocument = TaggedSanityDocument<TaggedDocumentTypes>;

export interface TaggedGridItemProps extends TaggedGridItemDocument {
    path?: string;
    category?: string;
    title?: string;
    ingress?: string;
    bannerImage?: ImageWithMetadata;
    showIngress?: boolean;
    showCategory?: boolean;
    hideImage?: boolean;
    hideTags?: boolean;
    desktopImageWidth?: number;
}

export default function TaggedGridItem({
    bannerImage,
    path,
    category,
    title,
    ingress,
    tags,
    showCategory = false,
    showIngress = false,
    hideImage = false,
    hideTags = false,
    desktopImageWidth,
}: TaggedGridItemProps) {
    const dataAttr = useDataAttribute();
    return (
        <a href={path} className={style.item}>
            <GridItemImage image={bannerImage} hideImage={hideImage} desktopWidth={desktopImageWidth} />
            <div className={style.info}>
                {showCategory && <span className={style.category}>{category}</span>}
                <h2>{title}</h2>
                {showIngress && <p>{ingress}</p>}
                {!hideTags && (
                    <ul className={style.tags} data-sanity={dataAttr?.('tags')}>
                        {tags?.filter(Boolean).map(({ name, _key }) => (
                            <li key={_key} data-sanity={dataAttr?.(['tags', { _key }])}>
                                {name}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </a>
    );
}
