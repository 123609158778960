import { SanityDocument } from '@sanity/client';
import { SanityAsset } from '@sanity/image-url/lib/types/types';
import { SanityPageDocument } from '../../layout/SanityHeaderFooterLayout';
import { AccordionGroup } from '../components/Accordion';
import { ArticleInfo } from '../components/ArticleInfo';
import Breadcrumbs from '../components/Breadcrumbs';
import { ContactCard } from '../components/ContactCard';
import { imageWithMetadataGroq } from '../components/SanityImage';
import { Grid } from '../grid/Grid';
import groq, { queryable } from '../infrastructure/groq';
import SanityPage from '../infrastructure/SanityPage';
import { RichText, richTextGroq } from '../richTextComponents';
import FileComponent from '../richTextComponents/fileComponent';
import { markDefsGroq } from '../richTextComponents/markDefsGroq';

interface SalesNumbersPage extends SanityPageDocument {
    _type: 'salesNumbersPage';
    contact?: {
        email: string;
        name: string;
        phone: string;
    };
    comments?: {
        title: string;
        items?: {
            _key: string;
            title: string;
            text: any;
        }[];
    };
    richText: any;
    salesNumbersTitle: string;
    salesNumbers?: SalesNumbersProps[];
}

export type SalesNumbersProps = SanityDocument & {
    _type: 'salesNumbersFile';
    title: string;
    file: {
        asset: SanityAsset & {
            size: number;
        };
    };
};

export const salesNumbersPageListGroq = groq`{
    _id,
    _type,
    slug,
    title,
    bannerImage ${imageWithMetadataGroq},
}`;

export const salesNumbersPage = queryable<SalesNumbersPage>(groq`
    *[_type == "salesNumbersPage" && _id == $id][0]{
        _id,
        _type,
        _updatedAt,
        hidden,
        slug,
        title,
        year,
        ingress,
        comments {
            title,
            items[] {
                _key,
                title,
                text[] {
                    ...,
                    markDefs[] ${markDefsGroq}
                }
            }
        },
        richText[] ${richTextGroq},
        salesNumbersTitle,
        salesNumbers[]-> {
            _id,
            _type,
            title,
            file {
                asset->
            },
        },
        metadata,
        contact -> {
            email,
            name,
            phone
        }
    }
`);

export default SanityPage<SalesNumbersPage>(({ page, breadcrumbs }) => (
    <article className="sanity" lang={page.metadata?.language}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <header>
            <h1>{page.title}</h1>
            <p>{page.ingress}</p>
        </header>
        <section>
            <RichText value={page.richText} />
            <AccordionGroup title={page.comments?.title} items={page.comments?.items} />
            {page.contact && <ContactCard name={page.contact.name} email={page.contact.email} phone={page.contact.phone} />}
            <ArticleInfo lastUpdated={page._updatedAt} />
        </section>
        <div className="end-of-article-grid">
            {page.salesNumbersTitle && <h2 className="lined-heading">{page.salesNumbersTitle}</h2>}
            <Grid items={page.salesNumbers ?? []} component={SalesNumberFile} autoFit />
        </div>
    </article>
));

function SalesNumberFile(value: SalesNumbersProps) {
    return <FileComponent value={value} category="Salgstall" />;
}
