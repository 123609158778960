import { stegaClean } from '@sanity/client/stega';
import cn from 'classnames';
import useOptimisticArray from '../../utils/useOptimisticArray';
import { TaggedSanityDocument } from '../grid/Grid';
import TaggedGridItem, { TaggedGridItemDocument, taggedGridItemGroq } from '../grid/TaggedGridItem';
import useDataAttribute, { ScopeDataAttribute } from '../infrastructure/DataAttributeContext';
import groq from '../infrastructure/groq';
import { KeyedArray } from '../types';
import { ImageWithMetadata } from './SanityImage';
import * as styles from './TeaserCards.module.less';

interface ExternalLink {
    _type: 'externalLink';
    title?: string;
    ingress?: string;
    bannerImage?: ImageWithMetadata;
    path?: string;
}

export interface TeaseCardsProps {
    title?: string;
    hideTitle?: boolean;
    ingress?: string;
    hideImages?: boolean;
    hideIngress?: boolean;
    hideTags?: boolean;
    showCategory?: boolean;
    backgroundColor?: 'green' | 'beige';
    itemBackgroundColor?: 'green' | 'beige';
    layout?: 'triple' | 'single-right';
    content: KeyedArray<TaggedGridItemDocument | ExternalLink>;
}

export interface TeaserCardSchema {
    _type: 'contentTeaser';
    _key: string;
    title?: string;
    hideTitle?: boolean;
    ingress?: string;
    hideImages?: boolean;
    hideIngress?: boolean;
    hideTags?: boolean;
    showCategory?: boolean;
    backgroundColor?: 'green' | 'beige';
    itemBackgroundColor?: 'green' | 'beige';
    layout?: 'triple' | 'single-right';
    content: KeyedArray<TaggedGridItemDocument | ExternalLink>;
}

export const teaserCardGroq = groq`{
    _type,
    _key,
    title,
    ingress,
    hideTitle,
    hideImages,
    hideIngress,
    showCategory,
    hideTags,
    backgroundColor,
    itemBackgroundColor,
    layout,
    content[]{
        _key,
        _type,
        _type == 'reference' => {...(@-> ${taggedGridItemGroq})},
        _type == 'externalLink' => {
            title,
            ingress,
            'path': link.href,
            'bannerImage': image
        }
    }
}`;

export default function TeaserCards({
    title,
    content,
    ingress,
    hideImages,
    hideIngress,
    hideTags,
    hideTitle,
    showCategory,
    ...props
}: TeaseCardsProps) {
    const dataAttr = useDataAttribute();
    const list = useOptimisticArray(content, 'content');
    const bg = stegaClean(props.backgroundColor);
    const itemBg = stegaClean(props.itemBackgroundColor);
    const layout = stegaClean(props.layout);
    return (
        <div className={cn(styles.teaser, bg && styles[bg], layout && styles[layout])} data-sanity={dataAttr?.([])}>
            {!hideTitle && (
                <>
                    <h2 className={bg ? undefined : 'lined-heading'}>{title}</h2>
                    <p>{ingress}</p>
                </>
            )}
            <ul data-sanity={dataAttr?.('content')} className={itemBg && styles[itemBg]}>
                {list
                    ?.filter((l) => l && (l._type === 'externalLink' ? l.path : l._id))
                    .map(({ _key, ...teaser }, _, { length }) => {
                        switch (teaser._type) {
                            case 'externalLink':
                                return (
                                    <li key={_key} data-sanity={dataAttr?.(['content', { _key }])}>
                                        <ScopeDataAttribute path={['content', { _key }]}>
                                            <TaggedGridItem
                                                {...(teaser as TaggedSanityDocument)}
                                                showIngress={!hideIngress}
                                                hideImage={hideImages}
                                                showCategory={showCategory}
                                                hideTags={hideTags}
                                                desktopImageWidth={calculateImageWidth(length)}
                                            />
                                        </ScopeDataAttribute>
                                    </li>
                                );
                            default:
                                return (
                                    <li key={_key} data-sanity={dataAttr?.(['content', { _key }])}>
                                        <ScopeDataAttribute id={teaser._id} type={teaser._type}>
                                            <TaggedGridItem
                                                {...teaser}
                                                showIngress={!hideIngress}
                                                hideImage={hideImages}
                                                showCategory={showCategory}
                                                hideTags={hideTags}
                                                desktopImageWidth={calculateImageWidth(length)}
                                            />
                                        </ScopeDataAttribute>
                                    </li>
                                );
                        }
                    })}
            </ul>
        </div>
    );
}

/**
 * This function calculates the width of the images in the section based on the same rules as css
 * This way we can load the nice and large images
 *
 * @param count number of images in block
 * @returns width of image in pixels
 */
function calculateImageWidth(count: number) {
    // If there are 4 or more images we fall back to the normal rule
    if (count > 3) return undefined;

    // There is a 40px gap between each image, so subtract that from
    // the available width
    return (1288 - (count - 1) * 40) / count;
}
