import type { SanityDocument } from '@sanity/client';
import SanityImage, { ImageWithMetadata, imageWithMetadataGroq } from '../components/SanityImage';
import TeaserCards, { teaserCardGroq, TeaserCardSchema } from '../components/TeaserCards';
import { ScopeDataAttribute } from '../infrastructure/DataAttributeContext';
import SanityPage from '../infrastructure/SanityPage';
import groq, { queryable } from '../infrastructure/groq';
import { KeyedArray } from '../types';

interface HomePage extends SanityDocument {
    _type: 'homePage';
    topModule: {
        title: string;
        buttons: KeyedArray<{ name: string; url: string }>;
    };
    content: KeyedArray<
        | TeaserCardSchema
        | {
              _type: 'services';
              buttons?: KeyedArray<{
                  link?: {
                      name: string;
                      url: string;
                  };
                  icon?: ImageWithMetadata;
              }>;
          }
    >;
}

export const homePage = queryable<HomePage>(groq`
    *[_type == "homePage" && _id == $id][0]{
        _id,
        _type,
        hidden,
        title,
        topModule,
        content[] {
            _key,
            _type,
            _type == 'services' => {
                buttons[]{
                    _key,
                    link,
                    icon ${imageWithMetadataGroq}
                }
            },
            _type == 'contentTeaser' => ${teaserCardGroq}
        }
    }
`);

export default SanityPage<HomePage>(({ page, dataAttribute }) => (
    <>
        <div className="top-module">
            <div>
                <h1>{page.topModule.title}</h1>
                <ul>
                    {page.topModule.buttons.map((b) => (
                        <li key={b._key}>
                            <a href={b.url}>{b.name}</a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
        <article className="sanity">
            <div className="category-page" data-sanity={dataAttribute?.('content')}>
                {page.content?.map(({ _key, ...props }) => {
                    switch (props._type) {
                        case 'contentTeaser':
                            return (
                                <ScopeDataAttribute key={_key} path={['content', { _key }]}>
                                    <TeaserCards {...props} />
                                </ScopeDataAttribute>
                            );
                        case 'services':
                            return (
                                <div key={_key} className="services">
                                    {props.buttons?.map((button) => (
                                        <a key={button._key} href={button.link?.url}>
                                            <SanityImage image={button.icon} />
                                            {button.link?.name}
                                        </a>
                                    ))}
                                </div>
                            );
                    }
                })}
            </div>
        </article>
    </>
));
