import { useContext } from 'react';
import { UserData } from '../api/product';
import Icon from '../generic/Icon';
import { UserContext } from '../login/userContext';
import ProductReviewModal from './ProductReviewModal';

interface Props {
    productCode: string;
    rating: number | undefined;
    comment: string;
    onUserDataChange(userData: UserData): void;
}

function trackOpenModal() {
    gtag('event', 'modal_view', {
        modal_name: 'ProductRatingTool',
    });
}

export default function ProductRatingTool({ rating, comment, productCode, onUserDataChange }: Props) {
    const { showLoginModal } = useContext(UserContext);

    const openModal = () => {
        if (!showLoginModal()) {
            ProductReviewModal.call({ rating, comment, productCode, onUserDataChange });
            trackOpenModal();
        }
    };

    return (
        <button className="btn-icon" onClick={openModal} title={'Gi produkt opp til 5 stjerner.'} aria-label={'Gi produkt opp til 5 stjerner.'}>
            <Icon className={rating ? `rating-star--active-${rating}` : 'rating-star--not-active'} />
        </button>
    );
}
